import React from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "../Context/theme";
import ProductForm from "./components/ProductForm";
import ProductView from "./components/ProductView";
import AdminSideBar from "./components/AdminSideBar";
import LoginForm from "./components/LoginForm";
import ProtectedRoute from "./auth/ProtectedRoute";
import AuthProvider from "./auth/AuthProvider";
import Dashboard from "./components/Dashboard";
import VerifyMFAForm from "./components/VerifyMFAForm";
import SignUp from "./components/SignUp";
import SetupAccount from "./components/SetupAccount";
import SetupTOTP from "./components/SetupTop";
import { SidebarProvider } from "./context/AdminNavBarContext";
import NavBar from "./components/NavBar";
import { Product } from "./auth/types";

const App: React.FC = () => {
  return (
    <AuthProvider>
      <ThemeProvider theme={theme}>
        <SidebarProvider>
          <CssBaseline />

          <NavBar />

          <Routes>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/verify-mfa" element={<VerifyMFAForm />} />
            <Route path="/setup-totp" element={<SetupTOTP />} />
            <Route path="/setup-account" element={<SetupAccount />} />


            //Local Development
            {/* <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route
              path="/admin/add-product"
              element={
                <ProductForm
                  onSave={(product: Product) => console.log(product)}
                />
              }
            />
            <Route
              path="/admin/view-products"
              element={<ProductView color="primary" />}
            /> */}


            {/* Wrap each protected route with ProtectedRoute */}
            <Route
              path="/add-product"
              element={
                <ProtectedRoute>
                  <AdminSideBar />{" "}
                  {/* Render Sidebar inside protected routes */}
                  <ProductForm
                    onSave={(product: Product) => console.log(product)}
                  />
                </ProtectedRoute>
              }
            />
            <Route
              path="/view-products"
              element={
                <ProtectedRoute>
                  <AdminSideBar />{" "}
                  {/* Render Sidebar inside protected routes */}
                  <ProductView color="primary" />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute>
                  <AdminSideBar />{" "}
                  {/* Render Sidebar inside protected routes */}
                  <Dashboard />
                </ProtectedRoute>
              }
            />
          </Routes>
        </SidebarProvider>
      </ThemeProvider>
    </AuthProvider>
  );
};

export default App;
