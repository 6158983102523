import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  Grid,
  InputAdornment,
} from "@mui/material";
import { useSidebar } from "../context/AdminNavBarContext"; // Import SidebarContext
import { useTheme } from "@mui/material/styles"; // Import theme
import { useAuth } from "../auth/AuthProvider";
import { Product, Size } from "../auth/types";

const categories = [
  "Limited Edition",
  "Original Scratchboards",
  "Prints and Stickers",
];

const heightOptions = ["8", "10", "12", "14", "16", "18"];
const widthOptions = ["8", "10", "12", "14", "16", "18"];

const ProductForm = ({
  onSave,
  product,
}: {
  onSave: (product: Product) => void;
  product?: Product;
}) => {
  const [title, setTitle] = useState(product?.title || "");
  const [description, setDescription] = useState(product?.description || "");
  const [sizes, setSizes] = useState<Size[]>(product?.sizes || []);
  const [image, setImage] = useState<string>(product?.image || "");
  const [category, setCategory] = useState(product?.category || "");
  const { uploadProduct, fetchProducts } = useAuth(); // Import uploadProduct and fetchProducts functions

  const theme = useTheme(); // Access theme colors
  const { expanded } = useSidebar(); // Get sidebar state

  const handleAddSize = () => {
    setSizes([
      ...sizes,
      { size: "8x10", price: { amount: 0, currency: "USD" } },
    ]);
  };

  const handleRemoveSize = (index: number) => {
    setSizes(sizes.filter((_, i) => i !== index));
  };

  const handleHeightChange = (index: number, value: string) => {
    const newSizes = sizes.map((size, i) =>
      i === index
        ? { ...size, size: `${value}x${size.size.split("x")[1]}` }
        : size
    );
    setSizes(newSizes);
  };

  const handleWidthChange = (index: number, value: string) => {
    const newSizes = sizes.map((size, i) =>
      i === index
        ? { ...size, size: `${size.size.split("x")[0]}x${value}` }
        : size
    );
    setSizes(newSizes);
  };

  const handlePriceChange = (index: number, value: string) => {
    const amount = parseInt(value, 10);
    if (isNaN(amount)) return;

    const newSizes = sizes.map((size, i) =>
      i === index ? { ...size, price: { ...size.price, amount } } : size
    );
    setSizes(newSizes);
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];
      const reader = new FileReader();
  
      reader.onloadend = () => {
        setImage(reader.result as string);
      };
  
      reader.readAsDataURL(file);
    }
  };
  

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    try {
      const product: Product = {
        id: "", // Placeholder, assuming the backend will generate this
        title,
        description,
        sizes,
        imageUrl: image,
        category,
        sold: false, // Initialize sold to false for a new product
      };
      await uploadProduct(product);
      await fetchProducts(); // Fetch the updated product list after successful upload
      console.log("Product uploaded successfully");
      // Handle success (e.g., show a message, redirect)
    } catch (error) {
      console.error("Failed to upload product", error);
      // Handle error (e.g., show an error message)
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 5,
        ml: expanded ? "240px" : "60px", // Adjust margin-left based on sidebar state
        transition: "margin-left 0.3s ease", // Smooth transition when sidebar toggles
        bgcolor: theme.palette.lightCyan.main, // Apply lightCyan background color
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography
        variant="h4"
        sx={{
          mb: 4,
          color: theme.palette.rose.main, // Apply rose color to title
          fontWeight: "bold",
        }}
      >
        Product Form
      </Typography>

      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12} md={6}>
          <TextField
            label="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{
              backgroundColor: theme.palette.background.paper,
              mb: 2,
            }}
          />
          <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            variant="outlined"
            fullWidth
            sx={{
              backgroundColor: theme.palette.background.paper,
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel>Category</InputLabel>
            <Select
              value={category}
              label="Category"
              onChange={(e) => setCategory(e.target.value)}
              sx={{
                backgroundColor: theme.palette.background.paper,
              }}
            >
              {categories.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {sizes.map((size, index) => {
          const [currentHeight, currentWidth] = size.size.split("x");
          return (
            <React.Fragment key={index}>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Height</InputLabel>
                  <Select
                    value={currentHeight}
                    label="Height"
                    onChange={(e) => handleHeightChange(index, e.target.value)}
                    sx={{ backgroundColor: theme.palette.background.paper }}
                  >
                    {heightOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel>Width</InputLabel>
                  <Select
                    value={currentWidth}
                    label="Width"
                    onChange={(e) => handleWidthChange(index, e.target.value)}
                    sx={{ backgroundColor: theme.palette.background.paper }}
                  >
                    {widthOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  label="Price"
                  type="number"
                  value={size.price.amount || ""}
                  onChange={(e) => handlePriceChange(index, e.target.value)}
                  variant="outlined"
                  fullWidth
                  sx={{ backgroundColor: theme.palette.background.paper }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleRemoveSize(index)}
                >
                  Remove
                </Button>
              </Grid>
            </React.Fragment>
          );
        })}
        <Grid item xs={12}>
          <Button
            onClick={handleAddSize}
            variant="contained"
            sx={{ backgroundColor: theme.palette.rose.main }}
          >
            Add Size
          </Button>
        </Grid>

        <Grid item xs={12} md={6}>
          <Button
            variant="outlined"
            component="label"
            fullWidth
            sx={{ borderColor: theme.palette.gray.main }}
          >
            Upload Image
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
        </Grid>

        <Grid item xs={12} md={6}>
          {image && (
            <Box
              component="img"
              src={image}
              alt="Image Preview"
              sx={{
                maxHeight: 200,
                maxWidth: "100%",
                objectFit: "contain",
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            sx={{
              backgroundColor: theme.palette.lightBlue.main,
              "&:hover": { backgroundColor: theme.palette.lightBlue.dark },
            }}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductForm;
