import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Product } from "../auth/types"; // Import the Product type
import { useSidebar } from "../context/AdminNavBarContext"; // Context for sidebar state
import { useTheme } from "@mui/material/styles"; // Import theme
import { useAuth } from "../auth/AuthProvider";
import { configFetchProducts } from "../auth/aws-config";

const ProductView = ({ color }: { color: "primary" | "secondary" }) => {
  const [products, setProducts] = useState<Product[]>([]);
  const { expanded } = useSidebar(); // Get the expanded state of the sidebar
  const theme = useTheme(); // Access theme colors
  const { markProductAsSold } = useAuth();

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    const response = await fetch(configFetchProducts.API_ENDPOINT_FETCH_PRODUCTS);
    if (!response.ok) {
      console.error("Failed to fetch products:", response.statusText);
      return;
    }
    const data = await response.json();
    setProducts(data);
  };

  const deleteProduct = async (productId: string) => {
    if (!window.confirm("Are you sure you want to delete this product?"))
      return;

    const response = await fetch(`/api/products/${productId}`, {
      method: "DELETE",
    });
    if (!response.ok) {
      console.error("Failed to delete product:", response.statusText);
      return;
    }
    fetchProducts();
  };

  const handleMarkAsSold = async (productId: string) => {
    try {
      await markProductAsSold(productId);
      fetchProducts(); // Refresh the product list after marking as sold
    } catch (error) {
      console.error("Failed to mark product as sold:", error);
    }
  };

  return (
    <Paper
      sx={{
        margin: 2,
        padding: 2,
        ml: expanded ? 32 : 10,
        transition: "margin 0.3s ease",
        bgcolor: theme.palette.lightCyan.main,
        boxShadow: 3,
      }}
    >
      <Button
        component={Link}
        to="/add-product"
        variant="contained"
        sx={{
          backgroundColor: theme.palette.lightBlue.main,
          mb: 2,
          "&:hover": {
            backgroundColor: theme.palette.lightBlue.dark,
          },
        }}
      >
        Add New Product
      </Button>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ color: theme.palette.rose.main }}>Title</TableCell>
            <TableCell sx={{ color: theme.palette.rose.main }}>
              Description
            </TableCell>
            <TableCell sx={{ color: theme.palette.rose.main }}>
              Sizes and Prices
            </TableCell>
            <TableCell sx={{ color: theme.palette.rose.main }}>Edit</TableCell>
            <TableCell sx={{ color: theme.palette.rose.main }}>
              Delete
            </TableCell>
            <TableCell sx={{ color: theme.palette.rose.main }}>Sold</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((product) => (
            <TableRow key={product.id}>
              <TableCell>{product.title}</TableCell>
              <TableCell>{product.description}</TableCell>
              <TableCell>
                {product.sizes.map((size) => (
                  <Typography
                    key={size.size}
                    sx={{ color: theme.palette.cadetGray.main }}
                  >
                    {size.size}: ${size.price.amount / 100}{" "}
                    {size.price.currency.toUpperCase()}
                  </Typography>
                ))}
              </TableCell>
              <TableCell>
                <Button
                  component={Link}
                  to={`/edit-product/${product.id}`}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.rose.main,
                    "&:hover": { backgroundColor: theme.palette.rose.dark },
                  }}
                >
                  Edit
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => deleteProduct(product.id!)}
                  variant="contained"
                  color="secondary"
                  sx={{
                    backgroundColor: theme.palette.gray.main,
                    "&:hover": { backgroundColor: theme.palette.gray.dark },
                  }}
                >
                  Delete
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => handleMarkAsSold(product.id!)}
                  variant="contained"
                  color="primary"
                  sx={{
                    backgroundColor: theme.palette.lightBlue.main,
                    "&:hover": {
                      backgroundColor: theme.palette.lightBlue.dark,
                    },
                  }}
                  disabled={
                    product.sold ||
                    product.category !== "Original Scratchboards"
                  }
                >
                
                  {product.sold ? "Sold" : "Mark as Sold"}
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default ProductView;
